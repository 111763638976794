import React from 'react'

const Footer = () => {
  return (
    <div className='bg-black text-white justify-center py-4'>
        <div className='text-center justify-center'>
            <div>
            <p className='justify-center'>
            Copyright © 2024 PEPE'S MONEY TREE
          </p>
            </div>
        </div>
    </div>
  )
}

export default Footer
