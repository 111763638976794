import React from 'react';
import terminal from '../assets/img.jpg'

const About = () => {
  return (
    <div className='w-full bg-black text-white text-center'>
      <div className='max-w-[1240px] mx-auto px-4 py-16 '>
        <div id="roadmap">
        <h1>PEPE'S MONEY TREE</h1> <br /><br />
        <p className='text-3xl'>Pepe’s Money Tree is flourishing, with each leaf glistening in the market’s GREEN glow! After relentless dedication, it’s finally time to gather. As Pepe shakes the branches, gems rain down, ready to fuel the next moonshot. </p>


        <img className='justify-center' src={terminal} alt="/" />
     
        </div>
      </div>
    </div>
  );
};
export default About;
